import request from '@/utils/request';
export var fileTransferHeaders = {};
export var defaultUsersPaymentData = {
  id: 0,
  userId: undefined,
  transactionReference: undefined,
  transactionDate: undefined,
  status: undefined,
  amount: 0,
  paymentGateway: '',
  paymentMethod: undefined,
  bank: undefined,
  utrNumber: null,
  user: {
    id: undefined
  },
  transaction: []
};
export var getUsersPayments = function getUsersPayments(params) {
  return request({
    url: '/users-payments',
    method: 'get',
    params: params
  });
};
export var createUsersPayment = function createUsersPayment(data) {
  return request({
    url: '/users-payments',
    method: 'post',
    data: data
  });
};
export var getUsersPaymentById = function getUsersPaymentById(id) {
  return request({
    url: "/users-payments/".concat(id),
    method: 'get'
  });
};
export var updateUsersPayment = function updateUsersPayment(id, data) {
  return request({
    url: "/users-payments/".concat(id),
    method: 'patch',
    data: data
  });
};