var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c("el-col", { attrs: { span: 12 } }, [
            _c("div", { staticClass: "form-block" }, [
              _c(
                "div",
                { staticClass: "source" },
                [
                  _vm.payableAmount > 0
                    ? _c(
                        "el-form",
                        {
                          ref: "formData",
                          staticClass: "demo-form",
                          attrs: {
                            model: _vm.formData,
                            rules: _vm.rules,
                            "label-position": "left"
                          }
                        },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "Amount Received",
                                prop: "amount"
                              }
                            },
                            [
                              _c("el-input-number", {
                                attrs: {
                                  name: "amount",
                                  precision: 2,
                                  step: 1,
                                  min: 1,
                                  max: _vm.payableAmount
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.toggleSelection()
                                  }
                                },
                                model: {
                                  value: _vm.formData.amount,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formData, "amount", $$v)
                                  },
                                  expression: "formData.amount"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "Transaction Date",
                                prop: "transactionDate"
                              }
                            },
                            [
                              _c("el-date-picker", {
                                attrs: {
                                  name: "transactionDate",
                                  type: "date",
                                  placeholder: "Pick a day",
                                  format: "dd-MM-yyyy",
                                  "picker-options": {
                                    disabledDate: _vm.isDateAllowed
                                  }
                                },
                                model: {
                                  value: _vm.formData.transactionDate,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.formData,
                                      "transactionDate",
                                      $$v
                                    )
                                  },
                                  expression: "formData.transactionDate"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "Payment Method",
                                prop: "paymentMethod"
                              }
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    filterable: "",
                                    name: "paymentMethod",
                                    placeholder: "Select"
                                  },
                                  model: {
                                    value: _vm.formData.paymentMethod,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.formData,
                                        "paymentMethod",
                                        $$v
                                      )
                                    },
                                    expression: "formData.paymentMethod"
                                  }
                                },
                                _vm._l(["Cash", "Cheque", "Online"], function(
                                  item
                                ) {
                                  return _c("el-option", {
                                    key: item,
                                    attrs: {
                                      required: "",
                                      label: item,
                                      value: item
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          ),
                          _vm.formData.paymentMethod &&
                          _vm.formData.paymentMethod !== "Cash"
                            ? _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "Transaction Reference",
                                    prop: "transactionReference"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    attrs: { name: "transactionReference" },
                                    model: {
                                      value: _vm.formData.transactionReference,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.formData,
                                          "transactionReference",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "formData.transactionReference"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "el-form-item",
                            [
                              _c(
                                "el-button",
                                {
                                  directives: [
                                    {
                                      name: "loading",
                                      rawName: "v-loading",
                                      value: _vm.loading,
                                      expression: "loading"
                                    },
                                    { name: "waves", rawName: "v-waves" }
                                  ],
                                  attrs: { type: "success" },
                                  on: { click: _vm.submitForm }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("global.form.save")) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ])
          ]),
          _c(
            "el-col",
            { staticStyle: { "margin-left": "10px" }, attrs: { span: 10 } },
            [
              _c(
                "div",
                { staticClass: "form-block" },
                [
                  _vm.user
                    ? _c("el-card", { staticClass: "box-card" }, [
                        _c(
                          "div",
                          {
                            staticClass: "clearfix",
                            attrs: { slot: "header" },
                            slot: "header"
                          },
                          [_c("span", [_vm._v("User Details")])]
                        ),
                        _c("div", { staticClass: "text item" }, [
                          _vm._v(
                            " Company: " + _vm._s(_vm.user.companyName) + " "
                          )
                        ]),
                        _c("div", { staticClass: "text item" }, [
                          _vm._v(" Name: " + _vm._s(_vm.user.fullName) + " ")
                        ]),
                        _c(
                          "div",
                          { staticClass: "text item" },
                          [
                            _vm._v(" Outstanding: "),
                            _c("inr", {
                              attrs: { number: _vm.user.totalOutstanding }
                            })
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "text item" },
                          [
                            _vm._v(" Paid: "),
                            _c("inr", { attrs: { number: _vm.user.totalPaid } })
                          ],
                          1
                        )
                      ])
                    : _vm._e()
                ],
                1
              )
            ]
          )
        ],
        1
      ),
      _c(
        "div",
        [
          _vm._v("Amount to split: "),
          _c("inr", { attrs: { number: _vm.amountToSettle } })
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.orderListLoading,
              expression: "orderListLoading"
            }
          ],
          key: _vm.tableKey,
          ref: "orderTable",
          staticStyle: { width: "100%" },
          attrs: { data: _vm.orderList, border: "", fit: "" },
          on: {
            select: _vm.handleSelectionChange,
            "select-all": _vm.uncheckAll
          }
        },
        [
          _c("el-table-column", { attrs: { type: "selection", width: "40" } }),
          _c("el-table-column", {
            attrs: { width: "200px", align: "left", label: "ID", prop: "id" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(" " + _vm._s(scope.row.id)),
                    _c("br"),
                    _c(
                      "el-tag",
                      {
                        attrs: {
                          type: _vm.statusColorMap[scope.row.status],
                          size: "mini"
                        }
                      },
                      [_vm._v(" " + _vm._s(scope.row.status) + " ")]
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              width: "150px",
              align: "left",
              label: "Grand Total",
              prop: "grandTotal"
            }
          }),
          _c("el-table-column", {
            attrs: {
              width: "150px",
              align: "left",
              label: "Amount Received",
              prop: "amountReceived"
            }
          }),
          _c("el-table-column", {
            attrs: { width: "150px", align: "left", label: "Outstanding" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          (
                            scope.row.grandTotal - scope.row.amountReceived
                          ).toFixed(2)
                        ) +
                        " "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              width: "160px",
              align: "left",
              label: "Adjusted Amount",
              prop: "payable"
            }
          }),
          _c("el-table-column", {
            attrs: {
              width: "200px",
              align: "left",
              label: "Placed On",
              prop: "createdTimestamp"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm._f("moment")(
                            scope.row.createdTimestamp,
                            "DD-MM-YYYY hh:mm a"
                          )
                        ) +
                        " "
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }