import request from '@/utils/request';
export var defaultUserData = {
  id: 0,
  enabled: true,
  companyName: null,
  fullName: '',
  emailAddress: '',
  mobileNumber: '',
  userType: undefined,
  avatar: '',
  organizationId: undefined,
  broker: {
    id: null
  },
  organization: {
    id: null,
    name: ''
  },
  usersRole: {
    id: null,
    name: ''
  },
  brokerName: '',
  // fssaiNumber: '',
  // gstNumber: '',
  // panNumber: '',
  usersRoleId: undefined,
  brokerId: undefined,
  // countryCode: '',
  // preferredLanguage: '',
  // isEmailVerified: undefined,
  // isMobileVerified: undefined,
  // gender: '',
  password: '',
  // referralCode: '',
  // canUseReferralCode: undefined,
  // lastLocation: '',
  // pushId: '',
  // platform: '',
  // version: '',
  // uuid: '',
  // model: '',
  // deviceId: '',
  // device: '',
  // OSVersion: '',
  // uniqueID: '',
  // manufacturer: '',
  // appVersion: '',
  referalCode: ''
};
export var defaultUserWalletData = {
  id: 0,
  enabled: true,
  amount: 0.0,
  action: 'credit',
  userId: null,
  orderId: null,
  user: {
    id: null
  },
  order: {
    id: null
  }
};
export var getUsers = function getUsers(params) {
  return request({
    url: '/users',
    method: 'get',
    params: params
  });
};
export var getUserById = function getUserById(id) {
  return request({
    url: "/users/".concat(id),
    method: 'get'
  });
};
export var getUserByEmail = function getUserByEmail(adminemail) {
  return request({
    url: "/users/".concat(adminemail),
    method: 'get'
  });
};
export var updateUser = function updateUser(id, data) {
  return request({
    url: "/users/".concat(id),
    method: 'patch',
    data: data
  });
};
export var deleteUser = function deleteUser(adminname) {
  return request({
    url: "/users/".concat(adminname),
    method: 'delete'
  });
};
export var login = function login(data) {
  return request({
    url: '/users/login',
    method: 'post',
    data: data
  });
};
export var logout = function logout() {
  return request({
    url: '/users/logout',
    method: 'post'
  });
};
export var register = function register(data) {
  return request({
    url: '/users',
    method: 'post',
    data: data
  });
};
export var userCount = function userCount(params) {
  return request({
    url: '/users/count',
    method: 'get',
    params: params
  });
};
export var getUserCards = function getUserCards() {
  return request({
    url: '/users-cards',
    method: 'get'
  });
};
export var getUserRoles = function getUserRoles(params) {
  return request({
    url: '/users-roles',
    method: 'get',
    params: params
  });
};
export var getUsersWallets = function getUsersWallets(params) {
  return request({
    url: '/wallet-transactions',
    method: 'get',
    params: params
  });
};
export var addFundsToUserWaller = function addFundsToUserWaller(data) {
  return request({
    url: '/wallet-transactions',
    method: 'post',
    data: data
  });
};